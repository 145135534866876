<template>
  <AuthWrapper>
    <v-card-text>
      <v-row align-content="center" justify="center">
        <v-col cols="12">
          <div class="forgot-top d-flex flex-column my-4">
            <v-btn class="text-center mx-auto" color="secondary" dark fab large>
              <v-icon dark> mdi-lock</v-icon>
            </v-btn>
            <h3 class="text-h3 text-center mb-3 mt-3">Password Recovery</h3>
          </div>
          <v-form v-model="forgotFormValidated" @submit.prevent="reset">
            <v-text-field
              v-model="resetForm.password"
              :error-messages="passwordError"
              :rules="validator.password"
              outlined
              placeholder="New Password"
              type="password"
            ></v-text-field>
            <v-text-field
              v-model="resetForm.confirmPassword"
              :error-messages="passwordError"
              :rules="validator.confirmPassword"
              outlined
              placeholder="Confirm Password"
              type="password"
            ></v-text-field>
            <v-btn :disabled="!forgotFormValidated" block color="secondary" type="submit">Reset</v-btn>
          </v-form>
          <div class="d-flex mt-3 mb-3 justify-space-between">
            <p>Remembered Password?
              <router-link :to="{name: 'Login'}">Login</router-link>
            </p>
            <p> Don't have Account?
              <router-link :to="{name: 'Register'}">Sign up</router-link>
            </p>
          </div>

          <div class="d-flex mx-auto flex-column">
            <h4 class="text-h6 text-center mt-3 mb-5 d-block">OR</h4>
            <v-btn :to="{name : 'Home'}" outlined>Back To Home</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </AuthWrapper>
</template>

<script>

import Auth from '@/api/Auth';
import Toast from '@/utils/Toast';
import AuthWrapper from '@/views/auth/AuthWrapper';

export default {
  name: 'ResetPassword',
  components: { AuthWrapper },
  data: () => ({
    forgotFormValidated: false,
    resetForm: {
      password: '',
      confirmPassword: '',
      token: '',
      email: ''
    },
    errors: {
      email: [],
    },
    validator: {
      password: [
        v => !!v || 'Password is required',
        v => v.length > 5 || 'Password must be 6 or more characters'
      ],
      confirmPassword: [
        (v) => {
          if (!v) {
            return 'Confirm Password is required';
          }
          if (v.length < 5) {
            return 'Password must be 6 or more characters';
          }
          return true;
        }
      ],
    }
  }),
  computed: {
    passwordError() {
      return this.errors?.password?.length ? this.errors.password[0] : '';
    },
  },

  methods: {
    validateUserAccess() {
      let token = this.$route.params.token || null;
      let email = this.$route.query.email || null;

      if (!token || !email) {
        return false;
      }

      this.resetForm.token = token;
      this.resetForm.email = email;

      return true;
    },

    async reset() {
      let data = {
        token: this.resetForm.token,
        email: this.resetForm.email,
        password: this.resetForm.password,
        password_confirmation: this.resetForm.confirmPassword
      };

      try {
        let reset = await Auth.reset(data);
        await Toast.success(reset.data.message);
        return await this.$router.push({ name: 'Login' });
      } catch (e) {

        if (e.errors) {
          this.errors = e.errors;
        }

        await Toast.error(e.message);
        await this.$router.push({ name: 'Login' });
      }
    }
  },

  beforeMount() {
    if (!this.validateUserAccess()) {
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>

<style lang="scss">
.forgot-page-content {
  height: 100%;
  min-height: 100%;
}

.forgot-left {
  background-image: url('../../assets/images/bg/login-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
